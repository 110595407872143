/** @jsx jsx */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { jsx, Flex, Input } from 'theme-ui';
import Link from './Link';

import { locNavigate } from '../state/session';

import { Button } from '.';
import { hideAllNotifications } from '../state/notifications';
import { ArrowIcon } from './Icon';

export default function TrackingSearch(props) {
  const [input, setInput] = useState(props.input || '');
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();

  function onSearch(e) {
    e.preventDefault();
    dispatch(hideAllNotifications());
    dispatch(locNavigate('/seuranta', '?parcelNumber=' + input));
  }

  function onInputChange(e) {
    const { value } = e.target;
    setInput(value.replace(/[^A-z0-9]/g, ''));
  }

  return (
    <form onSubmit={onSearch} sx={{ flex: 1 }}>
      <Flex
        sx={{
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Input
          placeholder={translate('tracking.search.title')}
          value={input}
          onChange={onInputChange}
          sx={{
            width: '100%',
            py: 12,
            px: 3,
          }}
        />
        <Button
          as={Link}
          to={'/seuranta?parcelNumber=' + input}
          onClick={onSearch}
          sx={{ color: 'gray', p: 0, position: 'absolute', right: 3 }}
          title={translate('tracking.search.button')}
          variant="plain"
        >
          <ArrowIcon />
        </Button>
      </Flex>
    </form>
  );
}
